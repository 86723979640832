.debug-container {
  @apply bg-base text-white fixed top-0 right-0 bottom-0 translate-x-full transition-[.2s];
}

.debug-content {
  @apply p-4 flex h-full;
  @apply max-w-[45rem] overflow-x-scroll;
}

.debug-container.open,
.debug-container:hover {
  @apply translate-x-0;
}

.debug-container .debug-trigger {
  @apply bg-base text-2xl absolute top-0 right-full flex items-center justify-center cursor-pointer w-14 h-14;
}
