@tailwind base;
@tailwind components;
@tailwind utilities;

.app-wrapper {
  @apply overflow-x-hidden w-full relative;
  @apply min-h-[100svh];
}

.content {
  max-width: 75em;
}

h1, .h1,
h2, .h2 {
  @apply text-3xl font-bold;
}

h3, .h3 {
  @apply text-xl font-bold;
}

.dashboard-head {
  @apply mb-6;
}

.dashboard-navi {
  @apply flex items-center gap-6 mb-6;
}

.no-result {
  @apply bg-base-5 p-12 text-center;
}

@import url("buttons.css");
@import url("form.css");
@import url("header.css");
@import url("modal.css");
@import url("sidebar.css");
@import url("table.css");
@import url("progressbar.css");
@import url("debug.css");
