.CircularProgressbar {
  @apply h-full align-middle;
}

.CircularProgressbar-path {
  @apply stroke-primary-dark;

  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar-trail {
  @apply stroke-base-5;

  stroke-linecap: round;
}

.CircularProgressbar-text {
  @apply fill-primary-dark text-xl;

  dominant-baseline: middle;
  text-anchor: middle;
}

.progressbar-wrapper {
  @apply relative flex items-center gap-4;

  height: 4rem;
}
