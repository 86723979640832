.modal {
  @apply fixed z-10 w-full h-full overflow-auto bg-[rgba(0,0,0,0.4)] p-8 left-0 top-0;
  @apply backdrop-blur;
  @apply flex justify-center items-center;
}

.modal-inner {
  @apply bg-white p-8 pt-12 relative;
  @apply w-[32rem];
}

.modal-close {
  @apply absolute top-0 right-0 p-4 cursor-pointer;
}
