button[type='submit'],
.btn,
.button-add,
.button-delete,
.button-down,
.button-up {
  @apply bg-base text-white whitespace-nowrap;
  @apply flex justify-center items-center rounded gap-2.5 px-6 py-3;
}

button[type='submit'].btn-brand,
.btn-brand {
  @apply bg-brand;
}

.btn-success {
  @apply bg-success;
}

.btn-error {
  @apply bg-error;
}

.btn[disabled],
.btn.disabled {
  @apply opacity-50 cursor-not-allowed;
}

.button-add {
  @apply mb-4;
}

.button-delete {
  @apply bg-base-80 mt-4;
}

.button-down,
.button-up {
  display: none;
}

.btn-group {
  @apply flex gap-4;
}
