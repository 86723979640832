.table {
}

.flex-table .thead,
.flex-table .tbody {
  @apply flex flex-col gap-4;
}

.flex-table .thead {
  @apply text-xs;
}

.flex-table .tr {
  @apply flex py-2 gap-4 items-center;
}

.flex-table .tbody .tr {
  @apply p-6;
  @apply border rounded border-gray-200 hover:bg-gray-50;
}

.tbody .tr.status {
  @apply border-l-4 border-l-base-15;
}

.tbody .tr.status.open {
  @apply border-l-base-15;
}

.tbody .tr.status.closed,
.tbody .tr.status.success,
.tbody .tr.status.done {
  @apply border-l-success;
}

.tbody .tr.status.warning,
.tbody .tr.status.in-progress {
  @apply border-l-warning;
}

.tbody .tr.status.error {
  @apply border-l-error;
}

.tbody .tr.status.closed {
  @apply opacity-30;
}

.flex-table.small .tbody .tr {
  @apply p-3;
}

.flex-table .tr > div.icon-warp { @apply w-1/12; }
.flex-table .tr > div.icon-warp > * { @apply ml-auto; }

.client-table .tr > div:nth-child(1) { @apply w-6/12; }
.client-table .tr > div:nth-child(2) { @apply w-3/12; }
.client-table .tr > div:nth-child(3) { @apply w-2/12; }

.submission-table .tr > div:nth-child(1) { @apply w-6/12; }
.submission-table .tr > div:nth-child(2) { @apply w-4/12; }
.submission-table .tr > div:nth-child(3) { @apply w-2/12; }
