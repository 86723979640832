.form-wrapper fieldset legend {
  @apply float-left;
  @apply font-bold text-lg pb-2;
}

.form-wrapper fieldset legend + * {
  @apply clear-both;
}

.form-wrapper {
  @apply flex flex-col desktop:flex-row;
}

.form-wrapper > .categorization-detail {
  @apply p-8 flex-1;
}

.form-wrapper label {
  @apply cursor-pointer;
}

.form-wrapper input[type="text"],
.form-wrapper input[type="email"],
.form-wrapper input[type="date"],
.form-wrapper select,
.form-wrapper textarea {
  @apply border rounded px-3 py-2 w-full;
}

.form-wrapper input[type="text"]:focus label ,
.form-wrapper input[type="email"]:focus label ,
.form-wrapper input[type="date"]:focus label ,
.form-wrapper select:focus label ,
.form-wrapper textarea:focus label {
   @apply shadow-input border-primary-dark outline-none;
}

/* Error */
.form-wrapper input[type="text"].invalid,
.form-wrapper input[type="email"].invalid,
.form-wrapper input[type="date"].invalid,
.form-wrapper select.invalid,
.form-wrapper textarea.invalid {
  @apply border-error;
}

/* Valid */
/*.form-wrapper input[type="text"].valid,*/
/*.form-wrapper input[type="email"].valid,*/
/*.form-wrapper input[type="date"].valid,*/
/*.form-wrapper select.valid,*/
/*.form-wrapper textarea.valid {*/
/*  @apply border-success;*/
/*}*/

.form-wrapper textarea {
  min-height: 8rem;
}

.form-wrapper .control label {
  @apply block pb-1.5;
}

.form-wrapper .control .validation {
  @apply pt-2 text-sm;
}

.form-wrapper .control .validation.validation_error {
  @apply text-error;
}

.form-wrapper .horizontal-layout {
  @apply flex flex-wrap gap-4;
}

.form-wrapper .horizontal-layout > * {
  @apply flex-1;
}

.form-wrapper .radio,
.form-wrapper .checkbox-wrap {
  @apply flex gap-4 flex-wrap;
}

.form-wrapper .checkbox-option,
.form-wrapper .radio-option {
  @apply relative;
}

.form-wrapper .checkbox-option > input[type="checkbox"],
.form-wrapper .radio-option > input[type="radio"] {
  @apply absolute -translate-y-1/2 left-3 top-1/2 z-10;
}

.form-wrapper .checkbox-option > input[type="checkbox"]:checked + label,
.form-wrapper .radio-option > input[type="radio"]:checked + label {
  @apply shadow-input border-primary-dark;
}

.form-wrapper .checkbox-option > label,
.form-wrapper .radio-option > label {
  @apply bg-white border rounded relative flex justify-center items-center gap-2 px-3 py-2 pl-8 leading-none;
}

.form-wrapper .group-layout {
  @apply w-full;
}

.form-wrapper .categorization-detail-new > .group-layout > .group-layout-item,
.form-wrapper .categorization-detail > div > .group-layout > .group-layout-item {
  @apply flex;
}

.form-wrapper .categorization-detail-new > .group-layout > .group-layout-item > *,
.form-wrapper .categorization-detail > div > .group-layout > .group-layout-item > * {
  @apply bg-base-5 mb-6;
  @apply p-6;
  @apply flex-1;
}

.form-wrapper .categorization-detail-new > .group-layout > .group-layout-item .group-layout-item + .group-layout-item ,
.form-wrapper .categorization-detail > div > .group-layout > .group-layout-item .group-layout-item + .group-layout-item {
  @apply mt-6;
}

.admin-form-view {
  label {
    cursor: default;
  }

  .button-add,
  .button-delete {
    @apply hidden;
  }
}
