.sidebar-wrapper,
.sidebar {
  @apply desktop:w-[22rem] flex-shrink-0;
}

.sidebar {
  @apply bg-base-5;
  @apply flex flex-initial p-8 flex-col gap-4 top-0 left-0 bottom-0;
  @apply desktop:fixed;
}

.sidebar-content {
  @apply flex-1;
  @apply border-t pt-4;
}

.sidebar-footer {
  @apply flex flex-col gap-4;
}

.sidebar-footer  > * + * {
  @apply border-t pt-4;
}

.sidebar-title {
  @apply text-2xl font-bold;
  @apply border-b pb-4 mb-4;
}

.sidebar-navi {
  @apply flex flex-col gap-4;
}

.sidebar-navi > div {
  @apply cursor-pointer truncate;
}

.sidebar-navi > div.active {
  @apply font-bold;
}

.sidebar-cta {
  @apply flex flex-col gap-3 pt-8;
}
