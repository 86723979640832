.header {
  @apply flex flex-col gap-4 pb-4;
}

.header h2 {
  @apply text-2xl font-bold;
}

.header-actions {
  @apply flex items-center gap-4;
}

.header-actions-right {
  @apply flex items-center ml-auto gap-4;
}

.header-actions-right > * {
  @apply flex items-center gap-4;
}

.header-actions-right * + * {
  @apply border-l pl-4;
}
